body,
html {
    font-family: 'Open Sans', sans-serif;
    background-color: #e4f1da;
    font-size: 10pt;
    padding: 0px;
    margin: 0px;
    min-height: 100vh;
}
.white-bg {
    background-color: #fff;
}
.fchp-input {
    background-color: #F2F2F2;
    color: $black;
    padding: 8px;
    border-radius: 2px;
    border: 1px solid #E6E6E6;
    transition: 0.3s;
    height: 38px;
}
.fchp-input-100 {
    background-color: #F2F2F2;
    color: $black;
    padding: 8px;
    border-radius: 2px;
    border: 1px solid #E6E6E6;
    transition: 0.3s;
    height: 38px;
    width: 100%;
}

.fchp-button {
    background-color: #367C2B;
    color: #fff;
    border: 0px;
    border-radius: .25rem;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    display: inline-block;
    font-weight: 400;
    transition: 0.3s;
    text-decoration: none;
    cursor: pointer;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

    .fchp-button:hover {
        background-color: #6da93d;/*#F5FAF1*/
    }

    .fchp-button:disabled {
        cursor: not-allowed;
    }

    .fchp-button:active {
        background-color: #6da93d;
    }

.app-header {
    padding: 15px;
    margin-bottom: 3px;
    background-color: white /*#e4f1da;*/
}
.app-main{

}
/* SCROLLBAR WEBKIT */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
}


/* WRAP CONTAINERS */
.app-container {
    position: relative;
    background-color: #e4f1da;
    min-height: 100vh;
    width: 100%;
}

.app-container-login {
    background: #e4f1da;
    position: relative;
    display: flex;
    min-height: 100vh;
    width: 100%;
}

/* DATA LIST */
.fchp-data-list:first-child {
    padding-top: .25rem;
    padding-bottom: .25rem;
    background-color: #6da93d;
    color: white;
    font-size: 1.5rem;
}

.fchp-data-list:nth-child(2) {
    padding-top: .25rem;
    padding-bottom: .25rem;
    background-color: #E6E6E6;
}

.fchp-data-list:nth-child(2n+4) {
    background-color: white;
    border-bottom: thin solid lightgrey;
    margin-top: .25rem;
    margin-bottom: .25rem;
    color: black;
}

.fchp-data-list:nth-child(2n+3) {
    background-color: white;
    border-bottom: thin solid lightgrey;
    padding-top: .25rem;
    padding-bottom: .25rem;
    margin-top: .25rem;
    margin-bottom: .25rem;
    color: black;
}
.hover-list:hover {
    background-color: lightgray;
}
.click-list {
    background-color: #e4f1da;
}
.click-list:hover{
    cursor:pointer;
}
.click-list-subitem {
    background-color: #f9fff5;
}
.grouped-accordion {
    border-width: 1px;
    border-style: solid;
    border-color: #6da93d;
}